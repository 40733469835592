import * as React from "react"
import { Container, Stack } from "react-bootstrap"
import Seo from '../components/seo/seo'
import './styles/index.scss';
import TeamCards from '../components/team-cards/team-cards';
import { graphql } from 'gatsby'

const aboutUs = ({ data }) => {
  return (
    <div className="background-theme">
    <Container className="pt-5 pb-5 internal-pages-common about-us">
      <h1>{data.allDatoCmsTeamPage.nodes[0].slugPage.title}</h1>
      <Stack className="card-long">
        <img alt="card-long-first" src={data.allDatoCmsTechnology.nodes[0].bannerPage.url} />
        <div className='back-image'>
          <h4>Authentic Digital Trading Cards</h4>
          <p>Creation / Minting / Marketing Custom Curated Collectibles</p>
        </div>
      </Stack>
      <Stack>
        <div className='text-title-img left'>
          <div>
            <img alt="digitalTradingCards" src={data.allDatoCmsTeamPage.nodes[0].digitalTradingCards[0].image.url} />
            <h3>
              {data.allDatoCmsTeamPage.nodes[0].digitalTradingCards[0].title}
            </h3>
            <p dangerouslySetInnerHTML={{ __html: data.allDatoCmsTeamPage.nodes[0].digitalTradingCards[0].description }}>
            </p>
          </div>
        </div>
        <div className='text-title-img left'>
          <h3>
            {data.allDatoCmsTeamPage.nodes[0].weAreAnArtist[0].title}
          </h3>
          <div>
            <img alt="digitalTradingCards" src={data.allDatoCmsTeamPage.nodes[0].weAreAnArtist[0].image.url} />
            <p dangerouslySetInnerHTML={{ __html: data.allDatoCmsTeamPage.nodes[0].weAreAnArtist[0].description }}>
            </p>
          </div>
        </div>
      </Stack>
      <TeamCards />
    </Container>
  </div>
  )
}

export default aboutUs;

export const Head = ({ data }) => (
  <Seo
    title="About Us"
    description={data.allDatoCmsTeamPage.nodes[0].seo.description}
    image={data.allDatoCmsTeamPage.nodes[0].seo.image.url}
  />
)

export const query = graphql`
query TeamQuery {
  allDatoCmsTechnology {
    nodes {
      bannerPage {
        url
      }
    }
  }
  allDatoCmsTeamPage {
    nodes {
      seo {
        description
        title
        image {
          url
        }
      }
      slugPage {
        title
        slug
      }
      team {
        name
        jobDescription
        image {
          url
        }
      }
      digitalTradingCards {
        image {
          url
        }
        title
        description
      }
      weAreAnArtist {
        title
        description
        image {
          url
        }
      }
    }
  }
}
` 