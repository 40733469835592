import * as React from "react"
import './team-cards.css'
import { Row, Col } from "react-bootstrap"
import { useStaticQuery, graphql } from 'gatsby'
import Card from 'react-bootstrap/Card';

const TeamCards = () => {

    const data = useStaticQuery(graphql`
    query TeamCardsQuery {
        allDatoCmsTeamPage {
            nodes {
              slugPage {
                title
                slug
              }
              team {
                name
                jobDescription
                image {
                  url
                }
              }
            }
          }
    }
    `)


    return (

        <div className="pt-3 pb-5 border-out cards-collections">
            <Row>
                {
                    data.allDatoCmsTeamPage.nodes[0].team.map((team, index) =>
                        <Col className='mb-3' xs={6} sm={6} md={4} lg={3} key={index}>
                            <Card className='team-cards border-radius-2'>
                                <Card.Img variant="top" alt="Logo" src={team.image.url} />
                            </Card>
                        </Col> 
                    )
                }
            </Row>
        </div>

    );
}
export default TeamCards;